import Api from "@/services/api";
import {downloadData, removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/circuits", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllCurrent(filter) {
        return Api().get("/circuits/current", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByYear(year) {
        return Api().get("/circuits/year/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/circuit/" + id).then(res => this.formatRes(res.data));
    },
    findByCeiId(ceiId) {
        return Api().get("/circuits/cei/" + ceiId).then(res => this.formatRes(res.data));
    },
    create(circuit) {
        return Api().post("/circuit", circuit).then(res => this.formatRes(res.data));
    },
    update(circuit) {
        return Api().put("/circuit/" + circuit.id, circuit).then(res => this.formatRes(res.data));
    },
    delete(circuit) {
        return Api().delete("/circuit/" + circuit.id).then(() => circuit);
    },
    deleteList(selectedCircuits) {
        return Api().delete("/circuits", {data: selectedCircuits});
    },
    formatRes(e) {
        return e;
    },
    downloadExcel() {
        return Api().get("/circuits/excel", {responseType: 'blob'}).then(res => {
            downloadData(res);
        });
    }
}